import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { getPool, createAlert, updateAlert } from '../utils/bellapi.js';

import './editor.scss';

export default function AlarmEditor({ alarm, onClose }) {
    const location = useLocation();
    const [alarmData, setAlarmData] = useState({ 
        poolChain: alarm?.poolChain || 'bsc',
        poolAddress: alarm?.poolAddress || '',
        poolTokens: [{ address: null, symbol: 'Token', price:  '' }, { address: null, symbol: 'Token', price: '' }],
        targetToken: alarm?.targetToken || '',
        targetSymbol: alarm?.targetSymbol || '',
        targetValue: alarm?.targetValue || '',
        targetMod: alarm?.targetMod || ''
    });

    const [saveActive, setSaveActive] = useState(false);
    const [tokenActualPrice, setTokenActualPrice] = useState('');

    const handleSave = () => {
        if (saveActive){
            const tokenParam = new URLSearchParams(location.search).get('token');
            if (!tokenParam) return;

            if (alarm){
                updateAlert(tokenParam, alarm._id, {
                    ...alarmData
                })
                    .then(() => onClose())
                    .catch(() => null);
            }else{
                const { poolAddress, targetMod, targetToken, targetValue, poolChain, targetSymbol } = alarmData;
                createAlert(tokenParam, { poolAddress, targetMod, targetToken, targetValue, poolChain, targetSymbol })
                    .then(() => onClose())
                    .catch(() => null);
            }
        }
    };

    useEffect(() => {
        if (!alarmData.poolAddress) return;
        const tokenParam = new URLSearchParams(location.search).get('token');
        if (!tokenParam) return;

        getPool(alarmData.poolAddress, alarmData.poolChain, tokenParam).then((pool) => {
            setAlarmData(prevData => ({ 
                ...prevData, 
                poolTokens: [ 
                    { address: pool.token0address || null, symbol: pool.token0symbol || 'Token', price: pool.token0priceUsd || NaN }, 
                    { address: pool.token1address || null, symbol: pool.token1symbol || 'Token', price: pool.token1priceUsd || NaN } 
                ],
                targetToken: alarmData.targetToken,
            }));
        });

    }, [alarmData.poolChain, alarmData.poolAddress, location]);

    useEffect(() => {
        const validAlarm = (
            alarmData.poolAddress !== '' &&
            alarmData.poolChain !== '' &&
            alarmData.targetToken !== '' &&
            ['lesser', 'greater'].includes(alarmData.targetMod) &&
            !isNaN(Number(alarmData.targetValue)) &&
            Number(alarmData.targetValue) !== 0
        )

        setSaveActive(validAlarm);
    }, [alarmData]);

    useEffect(() => {
        setTokenActualPrice(alarmData.poolTokens.find((token) => token.address === alarmData.targetToken )?.price || '')
    }, [alarmData.targetToken, alarmData.poolTokens])

    return (
        <div className="alarm-editor-overlay">
            <div className="alarm-editor">
                <div className="editor-header">
                    <button className={`editor-btn ${saveActive ? '' : 'unactive'}`} onClick={handleSave}>Save</button>
                    <button className="editor-btn" onClick={onClose}>Close</button>
                </div>
                <div className="editor-content">
                    <div className='editor-settings'>
                        <h2>Alarm!</h2>
                        <div>
                            <h5>Chain
                                <select 
                                    className='editor-select' 
                                    onChange={(event) => {
                                        setAlarmData(prevData => ({ ...prevData, poolChain: event.target.value }))
                                    }}
                                    value={alarmData.poolChain}
                                >
                                    <option value="eth">ETH</option>
                                    <option value="bsc">BSC</option>
                                </select>
                            </h5>
                        </div>
                        <div>
                            <input
                                type="text"
                                value={alarmData.poolAddress}
                                onChange={(e) => setAlarmData(prevData => ({ ...prevData, poolAddress: e.target.value }))}
                                placeholder="Pool Address"
                            />
                        </div>
                        <div className='token-choise'>
                            { alarmData.poolTokens.map((token, tokenIndex) => {
                                return (
                                    <div 
                                        onClick={(() => {
                                            if (!token.address) return;
                                            setAlarmData(prevData => ({ ...prevData, targetToken: token.address, targetSymbol: token.symbol }))
                                        })}
                                        className={`token-choise__token ${token.address === alarmData.targetToken ? 'token-choise__selected' : ''}`} 
                                        key={tokenIndex}
                                    >
                                        {token.symbol}
                                    </div>
                                )
                            }) }
                        </div>
                        <div>
                            <h5>Current Price</h5>
                            <input
                                type="text"
                                value={tokenActualPrice}
                                style={{ border: tokenActualPrice ? '' : '2px solid gray' }}
                                placeholder="input pool address!"
                                readOnly={true}
                            />
                        </div>
                        {tokenActualPrice && 
                            <div>
                                <div>
                                    <h2>Settings:</h2>
                                </div>
                                <div>
                                    <h5>Target Price</h5>
                                    <input
                                        type="text"
                                        placeholder="value"
                                        value={alarmData.targetValue}
                                        onChange={(e) => {
                                            setAlarmData(prevData => ({ ...prevData, targetValue: e.target.value }))
                                        }}
                                    />
                                </div>
                                <div className='token-choise'>
                                    <div 
                                        className={`token-choise__token ${alarmData.targetMod === 'greater' ? 'token-choise__selected' : ''}`} 
                                        onClick={() => {
                                            setAlarmData(prevData => ({ ...prevData, targetMod: 'greater' }))
                                        }}
                                    >
                                        Greater
                                    </div>
                                    <div 
                                        className={`token-choise__token ${alarmData.targetMod === 'lesser' ? 'token-choise__selected' : ''}`} 
                                        onClick={() => {
                                            setAlarmData(prevData => ({ ...prevData, targetMod: 'lesser' }))
                                        }}
                                    >
                                        Lesser
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
