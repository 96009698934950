import axios from 'axios';

// https://bell.defiocean.io
const getUser = async (token) => {
    return axios.get('https://bell.defiocean.io/api/user/me', { params: { token } }).then(r => r.data);
}

const createAlert = async (token, payload) => {
    return axios.post('https://bell.defiocean.io/api/user/me/alert', payload, { params: { token, chain: payload.poolChain } }).then(r => r.data);
}

const updateAlert = async (token, alertId, payload) => {
    return axios.put('https://bell.defiocean.io/api/user/me/alert', payload, { params: { token, id: alertId } }).then(r => r.data);
}

const getPool = async(pool, chain, token) => {
    return axios.get('https://bell.defiocean.io/api/pool', { params: { pool, chain, token } }).then(r => r.data);
}

export {
    getUser,
    getPool,
    createAlert,
    updateAlert
}