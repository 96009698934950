import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getUser, updateAlert } from '../utils/bellapi.js';

import Loading from './Loading';
import AlarmEditor from './AlarmEditor';
import Unauthorized from './Unauthorized';

export default function Main(){
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [unauthorized, setUnauthorized] = useState(false);
    const [alarms, setAlarms] = React.useState([]);
    const [selectedAlarm, setSelectedAlarm] = useState(null);
    const [isEditorOpen, setIsEditorOpen] = useState(false);

    const toggleAlarm = (alert) => {
        const tokenParam = new URLSearchParams(location.search).get('token');
        if (!tokenParam) {
            setUnauthorized(true);
            setLoading(false);
        }

        setAlarms(prevData => prevData.map(alarm => alarm._id === alert._id ? { ...alarm, active: !alarm.active} : alarm));

        updateAlert(tokenParam, alert._id, {
            ...alert,
            active: !alert.active
        }).then((updated) => {
            setAlarms(prevData => prevData.map(alarm => alarm._id === alert._id ? updated : alarm));
        })
    };

    const openEditor = (alarm = null) => {
        setSelectedAlarm(alarm);
        setIsEditorOpen(true);
    };
    
    const closeEditor = () => {
        setIsEditorOpen(false);
    };

    useEffect(() => {
        const tokenParam = new URLSearchParams(location.search).get('token');
        if (!tokenParam) {
            setUnauthorized(true);
            setLoading(false);
        }
        getUser(decodeURIComponent(tokenParam)).then((user) => {
            setLoading(false);
            setUnauthorized(false);
            setAlarms(user.alerts);
        }).catch(() => {
            setUnauthorized(true);
            setLoading(false);
        })
    }, [location, isEditorOpen]);

    if (loading) return <Loading />
    if (unauthorized) return <Unauthorized />

    return (
        <div className='alarm-container'>
            <div className='alarm-new'>
                <button className='alarm-add' onClick={() => openEditor()}>[+]</button>
            </div>
            <div className='alarm-controller'>
                <div className="alarm-list">
                    {alarms.map((alarm, index) => (
                        <div className={`alarm ${alarm.isOn ? 'on' : 'off'}`} key={index}>
                            <span 
                                className="alarm-name"
                                onClick={() => {
                                    openEditor(alarm);
                                }}
                            >
                                    {alarm.targetSymbol}
                            </span>
                            <span className="status percent"></span>
                            <button className={`toggle-btn ${alarm.active ? 'on' : 'off'}`} onClick={() => toggleAlarm(alarm)}>
                                {alarm.active ? 'ON' : 'OFF'}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            {isEditorOpen && (
                <AlarmEditor alarm={selectedAlarm} onClose={closeEditor} />
            )}
        </div>
    );
}