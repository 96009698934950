import React from 'react';
import { Notify } from 'notiflix';

import Main from './components/Main';
import { HashRouter, Route, Routes } from 'react-router-dom';

Notify.init({ 
    position: 'right-bottom'
})

const App = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path='/*' element={<Main />} />
            </Routes>
        </HashRouter>
    )
}

export default App;
